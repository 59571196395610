import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Chip, Stack, useMediaQuery } from "@mui/material";
import advisorDashboardItemList from "../../utils/analysisData";
import { useEffect, useState } from "react";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";

const CoreBusinessLayout = ({
  children,
  currentOption,
  setCurrentOption,
  setApiId,
  apiId,
  assessmentName,
}) => {
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const getAssessmentData = async () => {
    try {
      setLoading(true);
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.findAllAssesmentMasterList,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          business_owner_id: sessionStorage.getItem("userId"),
        },
      });
      if (res.data.statusCode === 200) {
          setDashboardData(res?.data?.result?.assesmentDetails);
          /*setCurrentOption(
            res?.data?.result?.assesmentDetails[0]?.assesment_name
          );*/
          if (assessmentName && apiId) {
            setCurrentOption(assessmentName);
          } else {
            setCurrentOption(
              res?.data?.result?.assesmentDetails[0]?.assesment_name
            );
          }
          setLoading(false);
        /*setCurrentOption(
          res?.data?.result?.assesmentDetails[0]?.assesment_name
        );
        setDashboardData(res?.data?.result?.assesmentDetails);
        setLoading(false);*/
      } else {
        toast.error("Something went wrong.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong.");
      console.log(error);
    }
  };

  useEffect(() => {
    getAssessmentData();
  }, []);
  /*useEffect(() => {
    setCurrentOption(dashboardData[0]?.assesment_name);
  }, [apiId]);*/

  return (
    <div
      style={{
        backgroundColor: currentTheme === "DARK" ? "#151515" : "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          padding: "0.5% 3% 1rem 3%",
          "@media(max-width:800px)": {
            display: "flex",
            flexDirection: "column",
          },

          // marginBottom: "-2rem",
        }}
      >
        <List
          style={{
            paddingTop: "0",
            // overflowY: "scroll",
            // overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.5) 0px 2px 16px 0px",
              display: "flex",
              height: "78vh",
              // width: "300px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              alignItems: "center",
              gap: "2rem",
              flexDirection: "column",
              "@media(max-width:800px)": {
                ml: "0px",
                display: "none",
              },
            }}
          >
            <Box mt={4}>
              {dashboardData.length > 0 &&
                dashboardData
                  // .filter((item) => item?.is_active)
                  .map((item, i) => (
                    <ListItem
                      sx={{
                        "&.MuiListItem-root": {
                          paddingBottom: "4px",
                          paddingTop: "4px",
                        },
                      }}
                      key={i}
                    >
                      <ListItemButton
                        onClick={() => {
                          setCurrentOption(item?.assesment_name);
                          setApiId(item?.id);
                        }}
                        sx={{
                          borderRadius: "50px",
                          background:
                            apiId === item.id
                              ? "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)"
                              : currentTheme === "DARK"
                              ? "#111111"
                              : "#fff",
                          paddingRight: "20px",
                          color: apiId === item.id ? "#fff" : "gray",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          transition:
                            "background 0.3s, color 0.3s, transform 0.3s",
                          "&:hover": {
                            background:
                              apiId !== item.id
                                ? currentTheme === "DARK"
                                  ? "#000"
                                  : "#f5f5f5"
                                : undefined,
                            color: apiId !== item.id ? "#E46703" : undefined,
                            transform:
                              apiId !== item.id ? "scale(1.0)" : undefined,
                          },
                          "&.active": {
                            transform: "scale(0.9)",
                          },
                        }}
                      >
                        <ListItemText
                          primary={item?.assesment_name}
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "200px",
                              textOverflow: "ellipsis",
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
            </Box>
          </Box>
        </List>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: "none",
            "@media (max-width:800px)": {
              ml: "0px",
              display: "flex",
              flexDirection: "row",
              overflowX: "scroll",
              scrollbarWidth: "none", // For Firefox
              "&::-webkit-scrollbar": {
                display: "none", // For Safari and Chrome
              },
            },
          }}
        >
          {dashboardData?.map((item, index) => (
            <Chip
              sx={{
                background:
                  apiId === item.id
                    ? "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)"
                    : "#fff",
                // borderRadius: "20px",
                px: "20px",
                color: apiId === item.id ? "#fff" : "gray",
              }}
              onClick={() => {
                setCurrentOption(item?.assesment_name);
                setApiId(item?.id);
              }}
              label={item?.assesment_name}
              variant="outlined"
              // onClick={handleClick}
            />
          ))}
        </Stack>
        <div
          style={{
            width: "100%",
            height: "78vh",
            overflow: "auto",
            boxShadow: "rgba(99, 99, 99, 0.5) 0px 2px 16px 0px",
          }}
        >
          {children}
        </div>
      </Box>
    </div>
  );
};

export default CoreBusinessLayout;
